import { useState } from 'react';

function useFormFields(initialValues = {}) {
  const [formFields, setFormFields] = useState(initialValues);

  const createChangeHandler = (key) => (e) => {
    e.preventDefault();
    const value = e.target.value;
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };

  const changeHandlerWithVal = (key, value) => {
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };
  
  const resetForm = () => {
    setFormFields(initialValues);
  };

  const resetFormWithKey = (key) => {
    setFormFields((prev) => ({ ...prev, [key]: initialValues[key] }));
  };

  return {
    formFields,
    createChangeHandler,
    onChangeHandlerWithVal: changeHandlerWithVal,
    onResetForm: resetForm,
    onResetFormWithKey: resetFormWithKey,
  };
}

export default useFormFields;
